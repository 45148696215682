import styles from "./RequestList.module.css";
import editBtn from "../../assets/edit.png";
import completeBtn from "../../assets/check.png";
import { formatDateTime } from "../../util/dateUtil";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import MessageBox from "../../components/messageBox/MessageBox";
import Loading from "../../components/loading/Loading";
import { cloudFuncs } from "../../firebase/config";
import { httpsCallable } from "firebase/functions";


const RequestList = ({requestData, handleComplete }) => {

    return(
        <>
        {requestData.map((item)=>(
            <RequestInfo key={item.id} item={item} onComplete={handleComplete}/>
        ))}
        </>
    )

}

export default RequestList;

const RequestInfo = ({item}) =>{
  
    const  navigate = useNavigate();
    const [showMessageBox, setShowMessageBox] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [canEdit, setCanEdit] = useState(item.status === "NEW" ? true :false);

    useEffect(()=>{
        setCanEdit(item.status === "NEW" ? true :false);
    },[item])

    const handleComplete = async(id)=>{
        console.log('on complete', id);
        setShowMessageBox(false);
        setShowError(false);
        setShowLoading(true);
        let response;
        const fn = httpsCallable(cloudFuncs, "updateFoodRequestStatus");
        try{
            response = await fn({objectID:id});
            if (response.data.error){
                console.log(response.data.msg);
                setShowLoading(false);
                setShowError(true);
                setErrorMsg('Error marking food request complete. Please try again.');
            }
            else{
                setShowLoading(false);
                console.log('line 38 success');
            };
        }
        catch(err){
            setShowLoading(false);
            console.log(err);
            setShowError(true);
            setErrorMsg('Error marking food request complete. Please try again.');
        };
    };

    const handleEdit=()=>{
        navigate("/requests/edit",{state:{item:item}});
    };

    return(
        <div className={styles.itemContainer}key={item.id}>
            {/* line 1 in the card - date, icons*/}
            <div className={styles.line1}>
                <div className={styles.requestInfo}>
                    <p className={styles.itemText}>{formatDateTime(item.createdAt)}</p>
                </div>
                <div className={styles.icons}>
                    {!canEdit && <p className={styles.itemText}>{item.status}</p>}
                    {canEdit && <div>
                        <img onClick={()=>{handleEdit()}} src={editBtn} className={styles.editBtn} alt="Edit Btn"/>
                        <img onClick={()=>{setShowMessageBox(true)}} src={completeBtn} className={styles.completeBtn} alt="Complete Btn"/>
                    </div>}
                </div>
            </div>
            {/* line 1b in the card - name */}
            {item.requesterName && <div className={styles.line1}>
                <div className={styles.requestInfo}>
                    <p className={styles.itemText}>{item.requesterName}</p>
                </div>
            </div>}

            {/* line 2 in the card - email address*/}
            {item.requesterEmailAddress && <div className={styles.line2}>
                <p className={styles.itemText}>{item.requesterEmailAddress}</p>
            </div>
}
            {/* line 3 in the card - animal type and requested food description */}
            <div className={styles.line2}>
                <p className={styles.itemText}>{item.animalType}</p>
                <p className={styles.itemText}>{item.requestedFoodDescription}</p>
            </div>
            {/* line 4 in the card - comments*/}
            <div className={styles.line3}>
                <p className={styles.itemText}>{`Comment: ${item.comment == null ? '' : item.comment}`}</p>
            </div>
            {showLoading && <Loading/>}
            {showError && <p className={styles.errorMsg}>{errorMsg}</p>}
            {showMessageBox && <MessageBox info={'Do you really want to mark this request completed?'}
                mainBtnHandler={()=>{handleComplete(item.id)}} cancelBtnHandler={()=>{setShowMessageBox(false)}}/>}
        </div>
    )
};

