import styles from "./EditRequest.module.css";
import { formatDateTime } from "../../util/dateUtil";
import { useState } from "react";
import NavBar from "../../components/navBar/NavBar";
import { useLocation, useNavigate } from "react-router-dom";
import CancelSaveBtns from "../../components/cancelSaveBtns/CancelSaveBtns";
import Loading from "../../components/loading/Loading";
import { cloudFuncs } from "../../firebase/config";
import { httpsCallable } from "firebase/functions";

const EditRequest = () =>{
    const navigate = useNavigate();
   
    const [disableBtns, setDisableBtns] = useState(false);
    const [disableSave, setDisableSave] = useState(true);
    const location = useLocation();
    console.log('line 17', location.state.item.id);
    const [item, setItem] = useState(location.state.item);
    const [comment, setComment] = useState(location.state.item.comment ? location.state.item.comment : '');
    const [showError, setShowError] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');


    const onSave = async()=>{
        setShowError(false);
        setShowLoading(true);
        setDisableBtns(true);
        setDisableSave(true);
        console.log('line 25 save comment', location.state.item.id, comment);
        let response;
        const fn = httpsCallable(cloudFuncs, "updateFoodRequestComment");
        try{
            response = await fn({objectID:location.state.item.id, comment:comment});
            if (response.data.error){
                console.log(response.data.msg);
                setShowLoading(false);
                setShowError(true);
                setDisableBtns(false);
                setDisableSave(false);
                setErrorMsg('Error saving comment. Please try again.');
            }
            else{
                setShowLoading(false);
                setDisableBtns(false);
                setDisableSave(false);
                console.log('line 38 success');
                navigate('/requests');
            };
        }
        catch(err){
            setShowLoading(false);
            setDisableBtns(false);
            setDisableSave(false);
            console.log(err);
            setShowError(true);
            setErrorMsg('Error saving comment. Please try again.');
        };
    };
    
    const onCancel = () =>{
        navigate("/requests");    
    };


    const updateComment = (e) =>{
        if (disableSave && e.target.value.length > 0){
            setDisableSave(false);
        }
        setComment(e.target.value);
    };

    return(
        <div className={styles.container}>
            <NavBar showAdmin={true} />
            <p className={styles.title}>Edit Food Request</p>
            <div className={styles.itemContainer}key={item.id}>
                {/* line 1 in the card date and requester name*/}
                <div className={styles.line1}>
                    <div className={styles.requestInfo}>
                        <p className={styles.itemText}>{formatDateTime(item.createdAt)}</p>
                        <p className={styles.itemText}>{item.requesterName}</p>
                    </div>
                </div>
                {/* line 2 in the card - requester email address*/}
                <div className={styles.line2}>
                    <p className={styles.itemText}>{item.requesterEmailAddress}</p>
                </div>
                {/* line 3 in the card - animal type and food description*/}
                <div className={styles.line3}>
                    <p className={styles.itemText}>{item.animalType}</p>
                    <p className={styles.itemText}>{item.requestedFoodDescription}</p>
                </div>
                {/* line 4 in the card - comment*/}
                <div className={styles.line4}>
                    <div className={styles.commentContainer}>
                        <p className={styles.labelText}>{`Comment: `}</p>
                        <textarea rows={3} className={styles.comment} onChange={updateComment} value={comment} placeholder="Enter comment here"/>
                    </div>
                </div>
                
            </div>
            {showLoading && <Loading/>}
            {showError && <p className={styles.errorMsg}>{errorMsg}</p>}
            <CancelSaveBtns disableBtns={disableBtns} disableSave={disableSave} onSave={onSave} onCancel={onCancel}/>
        </div>
    )
    
};
export default EditRequest;