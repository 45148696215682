import styles from "./Requests.module.css";
import { useEffect, useState } from "react";
import { fireDB } from "../../firebase/config";
import {collection, query, where, onSnapshot} from "firebase/firestore";
import RequestList from "../../components/requestList/RequestList";
import NavBar from "../../components/navBar/NavBar";


const Requests = () =>{

   
    const [requesterData, setRequesterData] = useState([]);
    const [allSelected, setAllSelected] = useState(false);
    const [newSelected, setNewSelected] = useState(true);
    const [q, setQ] = useState(query(collection(fireDB, "food_requests"), where("status", "==", "NEW")))
   
    const selectAll = () =>{
        setAllSelected(true);
        setNewSelected(false);
        setQ(query(collection(fireDB, "food_requests")));
    
    }
    const selectNew = () =>{
        setAllSelected(false);
        setNewSelected(true);
        setQ(query(collection(fireDB, "food_requests"), where("status", "==", "NEW")));
    }

    useEffect(() => {
        const unsub = onSnapshot(q, (querySnapshot)=>{
            let dataList = [];
            if (querySnapshot && querySnapshot.docs) {
                querySnapshot.forEach((doc) => {
                    dataList.push({
                    id: doc.id,
                    animalType: doc.data().animalType,
                    createdAt: doc.data().createdAt.toDate().toString(),
                    comment: doc.data().comment,
                    requestedFoodDescription: doc.data().requestedFoodDescription,
                    requesterEmailAddress: doc.data().requesterEmailAddress,
                    requesterName: doc.data().requesterName,
                    requesterPhoneNumber: doc.data().requesterPhoneNumber,
                    status: doc.data().status,
                    updatedAt: doc.data().updatedAt.toDate(),
                    });
                });
            }
                setRequesterData(dataList);
            })
            return () => unsub();
        },[q]);

    return(
        <div className={styles.container}>
            <NavBar showAdmin={true} />
            <div className={styles.titleContainer}>
                <p className={styles.title}>Maintain Food Requests</p>
                <div className={styles.listOptions}>
                    <p onClick={selectAll} className={allSelected ? styles.selected : styles.notSelected}>ALL</p>
                    <p onClick={selectNew} className={newSelected? styles.selected : styles.notSelected}>NEW</p>
                </div>
            </div>
            {requesterData.length >=1 && <RequestList requestData={requesterData}/>}
            {requesterData.length < 1 && <p className={styles.title}>No Requests Found</p>}
        
        </div>
    )
};
export default Requests;